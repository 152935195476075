(function() {
    'use strict';

    angular.module('ionicCheckIn', [
        'ionic',
        'ionicAlert',
        'ionicNewsfeed',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicCheckIn').service('CheckInCreateService', CheckInCreateService);

    CheckInCreateService.$inject = [
        'NewsfeedPostCreateService'
    ];

    function CheckInCreateService(
        NewsfeedPostCreateService
    ) {
        var CheckInCreateService = this;

        CheckInCreateService.createCheckIn = createCheckIn;
        function createCheckIn(newCheckIn) {
            return NewsfeedPostCreateService.createPost(newCheckIn);
        }

        CheckInCreateService.reset = reset;
        function reset() {

        }

        CheckInCreateService.reset();

        return CheckInCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicCheckIn').controller('CheckInCreateController', CheckInCreateController);

    CheckInCreateController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'IonicCheckInCreateService',
        '$scope'
    ];

    function CheckInCreateController(
        AlertService,
        DefaultVariableService,
        IonicCheckInCreateService,
        $scope
    ) {
        var CheckInCreateController = this;

        $scope.$watch(
            'hideAfter',
            function(hideAfter) {
                CheckInCreateController.hideAfter = DefaultVariableService.get(
                    hideAfter,
                    false
                );
            }.bind(CheckInCreateController)
        );

        CheckInCreateController.createCheckIn = createCheckIn;
        function createCheckIn() {
            CheckInCreateController.isCreatingCheckIn = true;

            return IonicCheckInCreateService.createCheckIn(
                CheckInCreateController.newCheckIn
            ).then(
                function(newCheckIn) {
                    if (newCheckIn) {
                        AlertService.addMessage('SUCCESSFUL CHECK IN!');

                        CheckInCreateController.hasCheckedIn = true;
                    }

                    return newCheckIn;
                }
            ).finally(
                function() {
                    CheckInCreateController.isCreatingCheckIn = false;
                }
            );
        }

        CheckInCreateController.reset = reset;
        function reset() {
            CheckInCreateController.isCreatingCheckIn = false;

            CheckInCreateController.defaultMessage = DefaultVariableService.get(
                CheckInCreateController.message,
                'CHECKING IN!'
            );

            CheckInCreateController.hasCheckedIn = false;

            CheckInCreateController.resetNewCheckIn();
        }

        CheckInCreateController.resetNewCheckIn = resetNewCheckIn;
        function resetNewCheckIn() {
            CheckInCreateController.newCheckIn = {};
            CheckInCreateController.newCheckIn.message = DefaultVariableService.get(
                CheckInCreateController.message,
                CheckInCreateController.defaultMessage
            );
        }

        CheckInCreateController.init = init;
        function init() {
            CheckInCreateController.reset();
        }

        CheckInCreateController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicCheckIn').directive('ionicCheckInCreate', ionicCheckInCreate);

    function ionicCheckInCreate() {
        return {
            bindToController: {
                message: '='
            },
            controller:   'CheckInCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                hideAfter: '='
            },
            template:'<div data-ng-if="!ctrl.hasCheckedIn || !ctrl.hideAfter" class="item item-input-inset"><label class="item-input row font-size-large"><i class="icon ion-chatbubble-working placeholder-icon"></i> <input type="text" data-ng-model="ctrl.newCheckIn.message" data-ng-disabled="ctrl.isCreatingCheckIn || ctrl.hasCheckedIn"></label> <button class="button accept-button" data-ng-click="ctrl.createCheckIn()" data-ng-disabled="ctrl.isCreatingCheckIn || ctrl.hasCheckedIn"><span data-ng-if="ctrl.isCreatingCheckIn">CHECKING IN...</span> <span data-ng-if="!ctrl.isCreatingCheckIn">{{ ctrl.hasCheckedIn ? \'CHECKED IN\' : \'CHECK IN\' }}</span></button></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicCheckIn').service(
        'IonicCheckInCreateService',
        IonicCheckInCreateService
    );

    IonicCheckInCreateService.$inject = [
        'CheckInCreateService'
    ];

    function IonicCheckInCreateService(
        CheckInCreateService
    ) {
        var IonicCheckInCreateService = this;

        IonicCheckInCreateService.createCheckIn = createCheckIn;
        function createCheckIn(newCheckIn) {
            return CheckInCreateService.createCheckIn(newCheckIn);
        }

        IonicCheckInCreateService.reset = reset;
        function reset() {

        }

        IonicCheckInCreateService.init = init;
        function init() {
            IonicCheckInCreateService.reset();
        }

        IonicCheckInCreateService.init();
    }
})();